import React from "react";
import PropTypes from "prop-types";
import BrandHero from "wonderbly-components/lib/BrandHero";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { useCountry } from "src/client/js/view/hooks";

const BrandHeroWrapper = (props) => {
  const country = useCountry();

  return (
    <BrandHero
      containerClass="container-guttered"
      title={props.title}
      description={props.description}
      images={props.items}
      autoPlay={props.carouselAutoplay}
      ctaLabel={props.ctaLabel}
      ctaLink={
        props.ctaLink.href ? localiseUrlII(country, props.ctaLink.href) : null
      }
      backgroundColour={props.backgroundColour}
    />
  );
};

BrandHeroWrapper.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColour: PropTypes.string,
  items: PropTypes.array,
  carouselText: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.object,
  carouselAutoplay: PropTypes.bool,
};

export default BrandHeroWrapper;
